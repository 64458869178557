import React, {
  useState,
  useTransition,
  useEffect,
  useCallback,
  useRef,
} from "react"
import { useStore } from "./Store"
// import { SceneEnvironment } from "./SceneEnvironment"
import { Cube } from "./Cube"
// import { useControls } from "leva"
import * as THREE from "three"

import { Canvas, useFrame } from "@react-three/fiber"
import {
  AccumulativeShadows,
  RandomizedLight,
  Center,
  Environment,
  OrbitControls,
  Html,
} from "@react-three/drei"
import Overlay from "./Overlay"
import { navigate } from "gatsby"


function Sphere() {
  const mesh = useRef()
  // const { roughness } = useControls({ roughness: { value: 1, min: 0, max: 1 } })
  const roughness = 1.0
  return (
    <Center top>
      <mesh ref={mesh} castShadow>
        <sphereGeometry args={[0.75, 64, 64]} />
        <meshStandardMaterial metalness={1} roughness={roughness} />
      </mesh>
    </Center>
  )
}

function SceneEnvironment() {
  const blur = 0.65 // min: 0, max: 1
  const preset = "dawn" // "sunset" "dawn" "night" "warehouse" "forest" "apartment" "studio" "city" "park" "lobby

  return <Environment preset={preset} background blur={blur} />
}

const Scene = props => {
  const canvasRef = useRef()
  const { cubes, addCube } = useStore()

  const [project_data, setProjectData] = useState(null)
  const [cam_poses, setCamPoses] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const init_camera_poses = cam_poses => {
    console.log("init_camera_poses")
    for (let i = 0; i < cam_poses["camera_poses"]["frames"].length; i++) {
      const object = cam_poses["camera_poses"]["frames"][i]

      const transform_matrix = object["transform_matrix"]
      const flat_transform_matrix = transform_matrix.flat()

      const transform_4x4 = new THREE.Matrix4()
      transform_4x4.set(
        flat_transform_matrix[0],
        flat_transform_matrix[1],
        flat_transform_matrix[2],
        flat_transform_matrix[3],
        flat_transform_matrix[4],
        flat_transform_matrix[5],
        flat_transform_matrix[6],
        flat_transform_matrix[7],
        flat_transform_matrix[8],
        flat_transform_matrix[9],
        flat_transform_matrix[10],
        flat_transform_matrix[11],
        flat_transform_matrix[12],
        flat_transform_matrix[13],
        flat_transform_matrix[14],
        flat_transform_matrix[15]
      )
      const scale_vector = new THREE.Vector3(0.1, 0.1, 0.1)
      transform_4x4.scale(scale_vector)

      addCube(
        transform_4x4,
        new THREE.Color(Math.random(), Math.random(), Math.random())
      )
    }
  }

  useEffect(() => {
    // get the project data first
    const token = localStorage.getItem("token")

    const project_backend_api = `${process.env.BACKEND_API_URL}/hub/project_status/${props.location.state.project_id}`
    const camera_poses_api = `${process.env.BACKEND_API_URL}/hub/camera_poses/${props.location.state.project_id}`
    console.log(project_backend_api)
    fetch(project_backend_api, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        if (response.status === 401) {
          // Redirect to login page if Unauthorized
          localStorage.removeItem("token")
          navigate("/login")
          return
        }
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          )
        }
        return response.json()
      })
      .then(actualData => {
        console.log("here2")
        setProjectData(actualData)
        setError(null)
      })
      .then(() => {
        fetch(camera_poses_api, {
          method: "GET",
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        })
          .then(response => {
            if (!response.ok) {
              throw new Error(
                `This is an HTTP error: The status is ${response.status}`
              )
            }
            return response.json()
          })
          .then(cam_poses_data => {
            setCamPoses(cam_poses_data)
            init_camera_poses(cam_poses_data)
            // threejs_init(cam_poses_data)
          })
      })
      .catch(err => {
        setError(err.message)
        setProjectData(null)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <>
      <Canvas
        ref={canvasRef}
        shadows
        camera={{ position: [0, 0, 4.5], fov: 50 }}
      >
        <group position={[0, -0.65, 0]}>
          <Sphere />
          <AccumulativeShadows
            temporal
            frames={200}
            color="purple"
            colorBlend={0.5}
            opacity={1}
            scale={10}
            alphaTest={0.85}
          >
            <RandomizedLight
              amount={8}
              radius={5}
              ambient={0.5}
              position={[5, 3, 2]}
              bias={0.001}
            />
          </AccumulativeShadows>
          {cubes.map((cube, index) => (
            <Cube
              key={index}
              matrix={cube.matrix}
              color={cube.color}
              scale={1}
            />
          ))}
        </group>
        <SceneEnvironment />
        <OrbitControls
          autoRotate
          autoRotateSpeed={0}
          enablePan={true}
          enableZoom={true}
        />
      </Canvas>
      <Overlay />
    </>
  )
}

export default Scene
