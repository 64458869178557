import { create } from "zustand"

export const useStore = create(set => ({
  cubes: [],
  addCube: (matrix, color) =>
    set(state => ({
      cubes: [
        ...state.cubes,
        {
          matrix,
          color,
        },
      ],
    })),
}))
