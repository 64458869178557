import React, { memo } from 'react'
import { House, CameraVideo, Gear, InfoCircle } from 'react-bootstrap-icons';
import { Link } from "gatsby"


const NavigationMenu = memo(() => {
    return (
        <div className="absolute left-5 top-5 w-10 bg-gray-200 rounded">
            <div className="btn-group btn-group-vertical">
                <Link to="/"  className="btn btn-active">
                    <button><House /></button>
                </Link>
                <button className="btn"><CameraVideo /></button>
                <button className="btn"><Gear /></button>
                <button className="btn"><InfoCircle /></button>
            </div>
        </div>
    )
})

export default NavigationMenu