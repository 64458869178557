import React from "react"
import Scene from "../../components/editor/Scene"

const EditorScanPage = ({ location }) => {
  console.log(location)
  return (
    <React.StrictMode>
      <div id="scene-container" className="h-screen w-screen">
        <Scene location={location} />
      </div>
    </React.StrictMode>
  )
}

export default EditorScanPage

export const Head = () => <title>Scan Editor</title>
