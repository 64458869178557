import React, { memo, useState } from 'react'

import NavigationMenu from './overlay/NavigationMenu'


function StartStopMenu() {
    const startGeneration = () => {
        console.log("start")
    }

    const stopGeneration = () => {
        console.log("Stop")
    }
    return (
        <div className="absolute left-1/2 transform -translate-x-1/2   top-5  rounded">
            <div className="btn-group btn-group-horizontal">
                <button className="btn" onClick={startGeneration}>
                    Start
                </button>
                <button className="btn" onClick={stopGeneration}>
                    Stop
                </button>
                <span className="btn btn-active btn-disabled">Iteration 0 of 300</span>
            </div>
        </div>
    )
}

function SidebarMenu() {
    const [sceneTabActive, setSceneTabActive] = useState(true)
    const [imagesTabActive, setImagesTabActive] = useState(false)
    const [outputTabActive, setOutputTabActive] = useState(false)


    const changeTab = event => {
        const clickedTab = event.target.id
        if (clickedTab === "scene") {
            setSceneTabActive(true)
            setImagesTabActive(false)
            setOutputTabActive(false)
        }
        if (clickedTab === "images") {
            setSceneTabActive(false)
            setImagesTabActive(true)
            setOutputTabActive(false)
        }
        if (clickedTab === "output") {
            setSceneTabActive(false)
            setImagesTabActive(false)
            setOutputTabActive(true)
        }
    }

    return (
        <div className="absolute right-5 top-5 w-64">
            <div className="tabs tabs-boxed bg-neutral  rounded-t-lg rounded-none">
                <button id="scene" className={sceneTabActive ? "tab tab-lifted tab-active " : "tab tab-lifted"} onClick={changeTab}>
                    Scene
                </button>
                <button id="images" className={imagesTabActive ? "tab tab-lifted tab-active" : "tab tab-lifted"} onClick={changeTab}>
                    Images
                </button>
                <button id="output" className={outputTabActive ? "tab tab-lifted tab-active" : "tab tab-lifted"} onClick={changeTab}>
                    Output
                </button>
            </div>
            <div className="bg-neutral rounded-b-lg h-52">
                <div id="scene_content" className={sceneTabActive ? "select-none" : "select-none hidden"}>
                    <span className='p-5'>Scene Tab</span>
                </div>
                <div id="scene_content" className={imagesTabActive ? "select-none" : "select-none hidden"}>
                    <span className='p-5'>Images Tab</span>
                </div>
                <div id="scene_content" className={outputTabActive ? "select-none" : "select-none hidden"}>
                    <span className='p-5'>Output Tab</span>
                </div>
            </div>
        </div>
    )
}


const Overlay = memo(() => {
    return (
        <><NavigationMenu />
            <StartStopMenu />
            <SidebarMenu />
        </>
    )
})

export default Overlay