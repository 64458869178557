import React, { useRef } from "react"
import { useFrame } from "@react-three/fiber"
import * as THREE from "three"

// const useCubeStore = create(set => ({
//   cubes: [],
//   addCube: (x, y, z) => set(state => ({ cubes: [...state.cubes, [x, y, z]] })),
// }))

// export const Cubes = () => {
//   const cubes = useCubeStore(state => state.cubes)
//   return cubes.map((coords, index) => <Cube key={index} position={coords} />)
// }

export function Cube(props) {
  const mesh = useRef()
  const { matrix, color, scale } = props
  useFrame(() => {
      mesh.current.matrixAutoUpdate = false
      mesh.current.matrix.copy(matrix)
      mesh.current.matrixWorldNeedsUpdate = true
  })

  return (
    <mesh ref={mesh} >
      <boxGeometry />
      <meshBasicMaterial color={color} />
    </mesh>
  )
}
